import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const eSignApi = createApi({
  reducerPath: "eSign",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_AUTH_URL}`,
    prepareHeaders: async (headers) => {
      const token = await sessionStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", "application/json; version=3.0");
      headers.set("Source", "LPLWEB");
      return headers;
    },
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => {
    return {
      fetchESignDoc: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/fetch_e_sign_doc/",
            method: "POST",
            body,
          };
        },
      }),
      sendOtp: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/send1_otp_crosssell/",
            method: "POST",
            body,
          };
        },
      }),
      verifyOtp: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/validate_otp_crosssell/",
            method: "POST",
            body,
          };
        },
      }),
      eSignKFS: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/e_sign/kfs/",
            method: "POST",
            body,
          };
        },
        transformResponse: (response, meta) => {
          response.statusCode = meta?.response?.status;
          return response
        }
      }),
    };
  },
});

export const {
  useFetchESignDocMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useESignKFSMutation,
} = eSignApi;

export { eSignApi };
